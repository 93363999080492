export default [
    'totalitarian',
    'hateSpeech',
    'racism',
    'xenophobia',
    'homophobia',
    'transphobia',
    'enbyphobia',
    'queerphobia',
    'exclusionism',
    'sexism',
    'misogyny',
    'ableism',
    'harassment',
    'impersonation',
    'selfHarm',
    'childPornography',
    'pedophilia',
    'unlawfulConduct',
    'misinformation',
    'doxxing',
    'spam',
    'trolling',
    'advertisement',
    'copyright',
];
